<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the user details</h4>
      <form autocomplete="off" @submit.prevent>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col
            v-if="['superAdmin', 'admin'].includes(loggedInUserType)"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <div class="w-full m-5">
              <doctor-type @changeDoctorType="changeDoctorType" />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('firstName') && doctorData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('firstName')"
                v-model="doctorData.firstName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('lastName') && doctorData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('lastName')"
                v-model="doctorData.lastName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">Date of Birth</label>
              <datepicker
                format="dd/MM/yyyy"
                v-model="doctorData.dateOfBirth"
                :input-class="{
                  'is-true':
                    !errors.has('dateOfBirth') && doctorData.dateOfBirth,
                  'is-danger': errors.has('dateOfBirth'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="doctorData.dateOfBirth"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('dateOfBirth')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("dateOfBirth")
              }}</span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="dateOfBirth"
                v-validate="'required'"
                v-model="doctorData.dateOfBirth"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('preferredName') &&
                  doctorData.preferredName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('preferredName') ? true : false"
                v-validate="'max:150'"
                name="preferredName"
                data-vv-as="preferred name"
                label="Preferred Name (Optional)"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('preferredName')"
                v-model="doctorData.preferredName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('email') && doctorData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('email')"
                v-model="doctorData.email"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('contactNumber') &&
                  doctorData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                :danger-text="errors.first('contactNumber')"
                data-vv-as="contact number"
                label="Contact Number"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                class="w-full"
                v-model="doctorData.contactNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('password') && doctorData.password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('password') ? true : false"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                name="password"
                data-vv-as="password"
                label="Password"
                icon-no-border
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('password')"
                autocomplete="new-password"
                v-model="doctorData.password"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>

          <vs-col
            v-if="
              loggedInUserType === 'clinic' || loggedInUserType === 'subClinic'
            "
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('rate') && doctorData.rate != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('rate') ? true : false"
                v-validate="{
                  required: true,
                  regex: /^\d*\.?\d*$/,
                }"
                name="rate"
                :danger-text="errors.first('rate')"
                data-vv-as="rate"
                label="Rate ($)"
                class="w-full"
                icon-no-border
                v-model="doctorData.rate"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('prescriberNumber') &&
                  doctorData.prescriberNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('prescriberNumber') ? true : false"
                v-validate="'required'"
                name="prescriberNumber"
                :danger-text="errors.first('prescriberNumber')"
                data-vv-as="prescriber number"
                label="Prescriber Number"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                class="w-full"
                v-model="doctorData.prescriberNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('ahpraRegistrationNumber') &&
                  doctorData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('ahpraRegistrationNumber') ? true : false"
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('ahpraRegistrationNumber')"
                data-vv-as="AHPRA Registration Number"
                label="AHPRA Registration Number"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                class="w-full"
                v-model="doctorData.ahpraRegistrationNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">AHPRA Expiry date</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="doctorData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && doctorData.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="doctorData.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraExiryDate")
              }}</span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                v-validate="'required'"
                v-model="doctorData.ahpraExiryDate"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceProvider') &&
                  doctorData.indemnityInsuranceProvider != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceProvider') ? true : false
                "
                v-validate="'required'"
                :danger-text="errors.first('indemnityInsuranceProvider')"
                name="indemnityInsuranceProvider"
                data-vv-as="indeminity insurance provider"
                label="Indemnity Insurance Provider"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="doctorData.indemnityInsuranceProvider"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceNumber') &&
                  doctorData.indemnityInsuranceNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceNumber') ? true : false
                "
                v-validate="'required|alpha_num'"
                name="indemnityInsuranceNumber"
                :danger-text="errors.first('indemnityInsuranceNumber')"
                data-vv-as="indemnity insurance number"
                label="Indemnity Insurance number"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="doctorData.indemnityInsuranceNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">Indemnity Insurance Expiry</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="doctorData.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('indemnityInsuranceExpiry') &&
                    doctorData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="doctorData.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("indemnityInsuranceExpiry")
              }}</span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="doctorData.indemnityInsuranceExpiry"
              />
            </div>
          </vs-col>

          <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div  class="w-full m-5">
              <label class="vs-input&#45;&#45;label">Address</label>
              <v-select
                v-model="doctorData.postalAddress"
                class="style-chooser"
                :class="{'six-pixel': errors.has('selectPostal')}"
                @search="searchSuburb"
                :options="items"
                :reduce="displayAddress => displayAddress.displayAddress"
                label="displayAddress"

                :danger="errors.first('selectPostal')"
                :success="!errors.first('selectPostal') && doctorData.postalAddress!=''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                name="selectPostal"
                data-vv-as="Postal Address"
                placeholder="Select Postal Address">
              </v-select>
              <span class="text-danger" style="font-size: 0.75em">{{ errors.first('selectPostal') }}</span>
            </div>
          </vs-col>-->

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">Address</label>
              <vs-select
                :danger="errors.first('address') ? true : false"
                :success="!errors.first('address') && doctorData.address !== ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="address"
                name="address"
                autocomplete
                v-model="doctorData.address"
                class="w-full"
                id="addressSuggestion"
                @input-change="autoCompleteFunc($event.target.value)"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in addressItems"
                />
              </vs-select>
              <span class="text-danger" style="font-size: 0.65rem !important">
                {{ errors.first("address") }}
              </span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('abn') && doctorData.abn != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('abn') ? true : false"
                name="abn"
                data-vv-as="ABN"
                label="ABN (Optional)"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('abn')"
                v-model="doctorData.abn"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-select
                :danger="errors.first('apType') ? true : false"
                :success="!errors.first('apType') && doctorData.apType !== ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                label="AP Type"
                data-vv-as="apType"
                name="apType"
                autocomplete
                v-model="doctorData.apType"
                class="w-full"
                id="apTypeSuggestion"
              >
                <vs-select-item key="doctor" value="Doctor" text="Doctor" />
                <vs-select-item
                  key="ap"
                  value="Physician Assistant"
                  text="Physician Assistant"
                />
                <vs-select-item
                  key="np"
                  value="Nurse Practitioner"
                  text="Nurse Practitioner"
                />
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          v-if="doctorData.apType !== 'Doctor' && doctorData.apType !== ''"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-select
                :danger="errors.first('authorizingDoctor') ? true : false"
                :success="
                  !errors.first('authorizingDoctor') &&
                  doctorData.authorizingDoctor !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                label="Authorizing Doctor"
                data-vv-as="authorizingDoctor"
                name="authorizingDoctor"
                autocomplete
                v-model="doctorData.authorizingDoctor"
                class="w-full"
                id="authorizingDoctorSuggestion"
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.firstName + ' ' + item.lastName"
                  v-for="(item, index) in doctorsList"
                />
              </vs-select>
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col
            id="aphraUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach AHPRA Certificate</label>
              <div
                :class="{ dateClass: !errors.has('ahpraCertificate') }"
                class="mt-6"
              >
                <div class="mb-4" v-if="doctorData.ahpraCertificate">
                  <div
                    v-if="
                      determineFileType(doctorData.ahpraCertificate) === 'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="doctorData.ahpraCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img :src="doctorData.ahpraCertificate" class="img-fluid" />
                  </div>
                </div>
                <div
                  :class="{ 'mt-3': doctorData.ahpraCertificate }"
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative; height: 45px"
                >
                  <label
                    id="ahpraId"
                    :class="{ 'six-pixel': errors.has('ahpraCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'aphraUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            id="insuranceUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach Insurance Certificate</label>
              <div
                :class="{ dateClass: !errors.has('insuranceCertificate') }"
                class="mt-6"
              >
                <div class="mb-4" v-if="doctorData.insuranceCertificate">
                  <div
                    v-if="
                      determineFileType(doctorData.insuranceCertificate) ===
                      'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="doctorData.insuranceCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img
                      :src="doctorData.insuranceCertificate"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div
                  :class="{ 'm-3': doctorData.insuranceCertificate }"
                  style="text-align: center; position: relative; height: 45px"
                >
                  <label
                    id="insuranceId"
                    :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'insuranceUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("insuranceCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            id="profileImageUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <!-- <label>Upload Profile Picture</label>
               <div id="profileUpload" v-on:change="chang('profileUpload')">
                 <vs-upload automatic
                            name ="profileImage"
                            action="/api/v1/doctors/upload"
                            text="Profile Picture"
                            accept=".png,.jpg,.jpeg"
                            limit="1"
                            v-validate="'required'"
                            @on-error="handleError('profileUpload')"
                            @on-delete="deleteFile('pp')"
                            @on-success="successUpload('pp',$event)" />
                 <span class="text-danger" style="font-size: 0.75em">{{ errors.first('profileImage') }}</span>
              </div>-->
              <label>Upload Profile Picture (Optional)</label>
              <div class="mt-6">
                <div
                  class="mb-4 large-img-wrapper"
                  v-if="doctorData.profileImage"
                  style="margin: auto"
                >
                  <img :src="doctorData.profileImage" class="img-fluid" alt />
                  <!-- <vs-avatar size="200px" align="center" :src="doctorData.profileImage" /> -->
                </div>
                <!--            <div class="mb-4" v-else style="margin:auto;height:50px;width:50%;">-->
                <!--              <img src="https://freshclinics-staging.s3.ap-southeast-2.amazonaws.com/undefined/tjl5fnug0jhy1588583500891.png" class="avatar" width="100%" height="100%">-->
                <!--            </div>-->
                <div
                  style="text-align: center; position: relative; height: 45px"
                >
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    Upload a profile photo
                    <input
                      type="file"
                      accept="image/*"
                      @change="uploadImage($event, 'profileImageUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
            </div>
          </vs-col>
          <!-- <vs-col
            id="signatureUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach Electronic Signature</label>
              <div :class="{ dateClass: !errors.has('electronicSignature') }" class="mt-6">
                <div class="mb-4" v-if="doctorData.signature">
                  <div
                    v-if="determineFileType(doctorData.signature) === 'pdf'"
                    style="margin:auto;width:50%;"
                  >
                    <a :href="doctorData.signature" target="_blank">Click here to view file</a>
                  </div>
                  <div v-else style="margin:auto;height:200px;width:50%;">
                    <img :src="doctorData.signature" style="width: 100%; height: 100%;" />
                  </div>
                </div>
                <div
                  :class="{ 'mt-3': doctorData.signature }"
                  style="text-align:center;position:relative;height: 45px;"
                >
                  <label
                    id="signatureId"
                    :class="{ 'six-pixel': errors.has('electronicSignature') }"
                    class="image-upload"
                    style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'signatureUpload')"
                      accept="image/*"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span
                class="text-danger"
                style="font-size: 0.75em"
              >{{ errors.first("electronicSignature") }}</span>
            </div>
          </vs-col>-->

          <vs-row>
            <vs-col vs-type="flex" vs-align="left" vs-w="12">
              <div class="w-full m-5" align="right">
                <vs-button
                  :disabled="!validateForm"
                  @click="saveData"
                  icon-pack="feather"
                  class="mr-2"
                  >Create Doctor</vs-button
                >
                <vs-button
                  color="danger"
                  @click="cancelHandler"
                  icon-pack="feather"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import axios from "@/axios";
import vSelect from "vue-select";
import moment from "moment";
import DoctorType from "./doctorType";

const dict = {
  custom: {
    firstName: {
      required: "Please enter the first name",
    },
    lastName: {
      required: "Please enter the last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter the contact number",
    },
    prescriberNumber: {
      required: "Please enter the prescriber number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    dateOfBirth: {
      required: "Please enter the date of birth",
    },
    ahpraCertificate: {
      required: "Please upload the AHPRA certificate file",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    insuranceCertificate: {
      required: "Please upload the insurance certificate file",
    },
    electronicSignature: {
      required: "Please upload the signature file",
    },
    profileImage: {
      required: "Please upload the profile image",
    },
    abn: {
      required: "Please enter valid ABN",
    },
    password: {
      required: "Please enter the password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    address: {
      required: "Enter valid address. Must choose from dropdown suggestion.",
    },
    rate: {
      required: "Please enter the doctor rate",
      regex: "Please enter valid rate for the doctor",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "create-doctor",
  props: {
    fetchClinicsBy: {
      type: String,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  components: {
    "v-select": vSelect,
    Datepicker,
    DoctorType,
  },
  data: () => ({
    addressItems: [],
    doctorsList: [],
    doctorData: {
      apType: "",
      authorizingDoctor: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      preferredName: "",
      contactNumber: "",
      prescriberNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      profileImage: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
      userType: "doctor",
      status: "Active",
      address: "",
      rate: "",
      dateOfBirth: "",
      abn: "",
      doctorType: "contract",
    },
    coordinates: [],
    disabledDates: { to: new Date() },
    clinics: [],
    clinic: "",
    checkDisplayAddress: "",
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    loggedInUserType: "",
  }),
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions("doctor", ["createDoctor"]),
    ...mapActions("franchise", ["fetchClinics"]),
    ...mapActions("clinic", ["getClinicDoctorRate"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    autoCompleteFunc(text) {
      if (text.length > 0)
        axios
          .get("/api/v1/getSuburbs", { params: { searchText: text } })
          .then((response) => {
            if (response.data.data.docs.length > 0) {
              const arr = response.data.data.docs.map((singleItem) => {
                singleItem.text = singleItem.displayAddress;
                singleItem.value = singleItem.displayAddress;
                return singleItem;
              });
              this.addressItems = [];
              this.addressItems = arr;
            }
          });
    },
    async saveData() {
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validate();
      if (
        moment(new Date(this.doctorData.dateOfBirth)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")
      ) {
        isValid = false;
        self.errors.add({
          field: "dateOfBirth",
          msg: "Doctor age must be 18 years or above",
        });
      } else {
        self.errors.remove("dateOfBirth");
      }

      if (this.doctorData.ahpraCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "ahpraCertificate",
          msg: "AHPRA Certificate is required",
        });
        document.getElementById("ahpraId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("ahpraId").style.borderColor = "#cccccc";
        self.errors.remove("ahpraCertificate");
      }
      if (this.doctorData.insuranceCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
        });
        document.getElementById("insuranceId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("insuranceId").style.borderColor = "#cccccc";
        self.errors.remove("insuranceCertificate");
      }
      // if (this.doctorData.signature == "") {
      //   isValid = false;
      //   self.errors.add({
      //     field: "electronicSignature",
      //     msg: "Electronic Signature is required",
      //   });
      //   document.getElementById("signatureId").style.borderColor = "#ea5455";
      // } else {
      //   self.errors.remove("electronicSignature");
      //   document.getElementById("signatureId").style.borderColor = "#cccccc";
      // }
      if (isValid) {
        let payload = {};
        this.doctorData.emailVerifiedAt = new Date().toISOString();
        payload.userDetails = this.doctorData;

        if (
          this.$store.state.AppActiveUser.userType === "superAdmin" ||
          this.$store.state.AppActiveUser.userType === "admin"
        ) {
          payload.userDetails.hasApprovedByAdmin = true;
        }

        if (
          this.loggedInUserType !== "superAdmin" &&
          this.loggedInUserType !== "admin" &&
          this.loggedInUserType !== "franchise" &&
          this.loggedInUserType !== "subFranchise"
        ) {
          let rate = payload.userDetails.rate
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
          payload.userDetails.rate = rate;
        } else {
          delete payload.userDetails.rate;
        }
        if (this.franchiseId && this.franchiseId !== "") {
          payload.userDetails.franchiseId = this.franchiseId;
        }

        await this.createDoctor(payload)
          .then((res) => {
            self.$vs.notify({
              title: "Doctor Created",
              text: "Doctor created successfully.",
              color: "success",
            });
            if (user === "superAdmin") {
              self.$router.push("/super-admin/doctors");
            } else if (user === "subFranchise") {
              self.$router.push("/franchise/doctors");
            } else if (user === "subClinic") {
              self.$router.push("/clinic/doctors");
            } else {
              self.$router.push("/" + user + "/doctors");
            }
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.$vs.notify({
                title: "Failed",
                text: "Failed to created doctor. Try again!",
                color: "danger",
              });
              self.mapServerErrors(self, err);
            }
            if (err.status === 409) {
              self.errors.add({
                field: "email",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }

            if (err.status === 417) {
              self.errors.add({
                field: "abn",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }

            if (err.status === 420) {
              self.errors.add({
                field: "dateOfBirth",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }
          });
      }
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.doctorData.ahpraCertificate = response.Location;
        this.errors.remove("ahpraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.doctorData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }
      if (tab == "s") {
        this.$vs.loading.close("#signatureUpload div div > .con-vs-loading");
        this.doctorData.signature = response.Location;
        this.errors.remove("electronicSignature");
      }
      if (tab == "pp") {
        this.doctorData.profileImage = response.Location;
        this.$vs.loading.close("#profileUpload div div > .con-vs-loading");
      }
      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.doctorData.ahpraCertificate = "";
      if (tab == "i") this.doctorData.insuranceCertificate = "";
      if (tab == "s") this.doctorData.signature = "";
      if (tab == "pp") this.doctorData.profileImage = "";
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.doctorData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.errors.remove("ahpraCertificate");
            document.getElementById("ahpraId").style.borderColor = "#cccccc";
            self.doctorData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceId").style.borderColor =
              "#cccccc";
            self.doctorData.insuranceCertificate = res.data.Location;
          }
          if (key === "signatureUpload") {
            this.errors.remove("electronicSignature");
            document.getElementById("signatureId").style.borderColor =
              "#cccccc";
            self.doctorData.signature = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    cancelHandler() {
      const user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/doctors");
      }
      if (user === "franchise") {
        this.$router.push("/franchise/doctors");
      }
      if (user === "clinic") {
        this.$router.push("/clinic/doctors");
      }
      if (user === "admin") {
        this.$router.push("/admin/doctors");
      }
    },
    place_changed(a) {},
    getSuburbs() {
      axios.get("/api/v1/getSuburbs").then((response) => {
        if (response.data.data.docs.length > 0) {
          const arr = response.data.data.docs.map((singleItem) => {
            singleItem.value = singleItem.displayAddress;
            singleItem.text = singleItem.displayAddress;
            return singleItem;
          });
          this.addressItems = arr;
        }
      });
    },
    changeDoctorType(type) {
      this.doctorData.doctorType = type;
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
    this.getSuburbs();
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;

    if (
      this.loggedInUserType === "clinic" ||
      this.loggedInUserType === "subClinic"
    ) {
      this.$vs.loading();
      this.getClinicDoctorRate()
        .then((res) => {
          this.doctorData.rate = res.data.data.rate;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log("this is the error :::", err);
          this.$vs.loading.close();
        });
    }
  },
  created() {
    this.doctorsList = this.$route.params.doctorsList;
    console.log("doctor list", this.$route.params.doctorsList);
  },
};
</script>
<style lang="scss">
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}
</style>
